import React, { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FallingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import image3 from "../../assets/images/image3.jpg";
import image4 from "../../assets/images/image4.jpg";
import image5 from "../../assets/images/image5.jpg";
import image6 from "../../assets/images/image6.jpg";
import send from "../../assets/svg/send.svg";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/Hooks/ScrollToTop";
import axios from "../../components/Hooks/axios";
import SmallHeader from "../../components/Small_Header/SmallHeader";
import { setIsOpenAds } from "../../redux/slices/AdsSlice";
import { setUserObject } from "../../redux/slices/authSlice";
import { setPollEnable } from "../../redux/slices/tempSlice";
import AdsApply from "../ADS/AdsApply/AdsApply";
import LiveIndex from "../Live/LiveIndex";
import PostWrapper from "../Post/PostWrapper";
import StoryWrapper from "../Stories/StoryWrapper";
import CustomPoll from "./CustomPoll";
import Post from "./Post";
import StoryItem from "./StoryItem";
import styles from "./index.module.scss";

const Home = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { userId, user } = useSelector((state) => state.auth);
	const { pollObj } = useSelector((s) => s.temp);
	const { isOpen } = useSelector((s) => s.ads);

	const [StoryPopup, setStoryPopup] = useState(false);
	const [PostPopup, setPostPopup] = useState(false);
	const [LivePopup, setLivePopup] = useState(false);
	const [StoryData, setStoryData] = useState([]);
	const [storyLoading, setStoryLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [caption, setCaption] = useState("");
	// Image 👇
	const [fileData, setFileData] = useState(null);
	const imageRef = useRef(null);
	// Image 👆
	const [rel, setRel] = useState(0);
	const [ownStory, setOwnStory] = useState(null);
	const [ownRel, setOwnRel] = useState(0);
	const mainContainer = useRef(null);
	const homeLeftContainer = useRef(null);
	const [showCustomPoll, setShowCustomPoll] = useState(false);

	const responsive = {
		0: {
			items: 2,
		},
		500: {
			items: 4,
		},
		600: {
			items: 5,
		},
		700: {
			items: 6,
		},
		800: {
			items: 7,
		},
		1024: {
			items: 8,
		},
	};

	useEffect(() => {
		setStoryLoading(true);
		axios
			.get("/story/stories")
			.then(({ data }) => {
				setStoryLoading(false);
				setStoryData(data);
			})
			.catch(({ response }) => {
				// setStoryLoading(false);
				console.log(response);
			});
	}, [rel]);

	useEffect(() => {
		if (userId === "") return;
		axios
			.get(`/story/mystory/${userId}`)
			.then(({ data }) => {
				if (data.status) {
					setOwnStory(data.data);
				}
			})
			.catch((e) => {
				console.log(e);
			});

		axios
			.get(`/auth/get-user-details/${userId}`)
			.then(({ data }) => {
				dispatch(setUserObject(data));
			})
			.catch((e) => {
				console.log(e);
			});
	}, [userId, ownRel]);

	const [isMobile, setIsMobile] = useState(window.innerWidth < 431);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 431);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className="App">
			<div className="MainContainer">
				<ScrollToTop />
				<Header />

				{/* NOTE: Custom Poll Popup 👇 */}
				{pollObj.customPollPopupShow && <CustomPoll />}
				{/* NOTE: Live 👇 */}
				{LivePopup && <LiveIndex setLivePopup={setLivePopup} />}
				{isOpen && <AdsApply />}

				<div className={`${styles.Container} ${styles.Home}`}>
					<SmallHeader />

					<div className={styles.homeContainer} ref={mainContainer}>
						<div
							className={styles.sliderContainer}
							style={isMobile ? null : { marginTop: StoryPopup || PostPopup ? "-20rem" : "0rem" }}>
							<div className={`${styles["slide-wrapper"]} ${styles.cl}`}>
								<div
									className={styles["slider-item"]}
									onClick={() => {
										if (ownStory?.stories.length === 0) toast.info("You have no Story please add a Story !");
										else navigate(`/Story/${userId}`, { state: { own: true } });
									}}>
									<div className={styles.image}>
										{ownStory?.stories?.length === 0 ? (
											<img src={user?.ProfilePic} />
										) : (
											ownStory?.stories[ownStory?.stories?.length - 1]?.mediaType === "image" && (
												<img src={ownStory?.stories[ownStory?.stories?.length - 1]?.mediaUrl} />
											)
										)}
									</div>
									<div
										className={styles.Plus}
										onClick={(e) => {
											e.stopPropagation();
											setStoryPopup(true);
											setPostPopup(false);
											// homeLeftContainer.current.scrollTop = 183;
										}}>
										+
									</div>
								</div>
							</div>

							{storyLoading ? (
								<div className={styles.loaderContainer}>
									<FallingLines color="#3A0DBB" width="100" visible={true} ariaLabel="falling-lines-loading" />
								</div>
							) : (
								<div className={styles.customSlick}>
									<AliceCarousel
										mouseTracking
										disableButtonsControls
										disableDotsControls
										responsive={responsive}>
										{StoryData?.map((data, index) => (
											<StoryItem key={index} data={data} />
										))}
									</AliceCarousel>
								</div>
							)}
						</div>

						<div className={styles.imagesMainContainer}>
							<div
								className={styles.homeLeftContainer}
								ref={homeLeftContainer}
								onScroll={(e) => {
									// console.log(mainContainer.current.scrollTop);
									mainContainer.current.scrollTop = e.target.scrollTop;
									if (e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight)
										setOffset(offset + 10);
								}}>
								{StoryPopup && !PostPopup && <StoryWrapper {...{ setStoryPopup, setOwnRel }} />}
								{PostPopup && !StoryPopup && <PostWrapper {...{ setPostPopup, fileData, setFileData }} />}
								{isMobile ? (
									<Post {...{ offset, setOffset }} />
								) : (
									!StoryPopup && !PostPopup && <Post {...{ offset, setOffset }} />
								)}
							</div>

							{isMobile ? null : (
								<div className={styles.homeRightContainer}>
									<div className={styles.messageBox}>
										<div className={styles.searchSection2}>
											<form>
												<input
													type="text"
													placeholder="Type something"
													value={caption}
													onChange={(e) => setCaption(e.target.value)}
													onClick={() => {
														setStoryPopup(false);
														setPostPopup(true);
													}}
												/>
												<button
													type="button"
													onClick={() => {
														// if (caption === "") toast.warn("Please Add Text!");
														// else navigate("/UploadPost", { state: { fileData, caption, fileType: "" } });
														setStoryPopup(false);
														setPostPopup(true);
													}}>
													<img src={send} />
												</button>
											</form>

											<ul>
												<input
													type="file"
													ref={imageRef}
													accept="image/*, video/*"
													style={{ display: "none" }}
													onChange={(e) => {
														setFileData(e.target.files[0]);
														setStoryPopup(false);
														setPostPopup(true);
														e.target.value = null;
													}}
												/>
												<li onClick={() => imageRef?.current?.click()}>Image</li>

												<li onClick={() => imageRef.current.click()}>Video</li>

												<li
													onClick={() => {
														setStoryPopup(false);
														setPostPopup(true);
														dispatch(setPollEnable(true));
														// setShowCustomPoll(true);
														// navigate("/UploadPost");
													}}>
													Poll
												</li>
												<li onClick={() => setLivePopup(true)}>Stream</li>
											</ul>
										</div>

										<div className={styles.ProgressBar}>
											<span style={{ width: `0%` }}></span>
										</div>
									</div>

									<div className={styles.staticImageContainer}>
										<div
											className={styles.ImageWrapper}
											onClick={() => dispatch(setIsOpenAds({ isOpen: true, path: "home" }))}>
											<img src={image3} alt="image3" />
										</div>
										<div className={styles.ImageWrapper}>
											<img src={image4} alt="image4" />
										</div>
										<div className={styles.ImageWrapper}>
											<div className={styles.ImageInner}>
												<img src={image5} alt="image5" />
											</div>
											<div className={styles.ImageInner}>
												<img src={image6} alt="image6" />
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
