import React from "react";
import { useState, useEffect } from "react";
import EP1 from "../../assets/images/EP1.jpg";
import EP2 from "../../assets/images/EP2.jpg";
import EP3 from "../../assets/images/EP3.jpg";
import EP4 from "../../assets/images/EP4.jpg";
import EP5 from "../../assets/images/EP5.jpg";
import EP6 from "../../assets/images/EP6.jpg";
import EP7 from "../../assets/images/EP7.jpg";
import styles from "./Explore.module.css";
const ExploreYourWorld = () => {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 427);
	const [showSearch, setShowSearch] = useState(false);
	const [Travel, setTravel] = useState(false);
	const [Funny, setFunny] = useState(false);
	const [Photography, setPhotography] = useState(false);
	const [News, setNews] = useState(false);
	const [Pets, setPets] = useState(false);
	const [RoadTrip, setRoadTrip] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 427);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return (
		<>
			<div className={styles.ExploreYourWorldContainer}>
				<div>
					<h1>Explore your World</h1>
				</div>
				<div className={styles.SearchContainer}>
					<input type="text" placeholder="Search..." className={showSearch ? styles.active : ""} />
					<button type="button" className={styles.btn} onClick={() => setShowSearch(!showSearch)}>
						<svg className={styles.SearchIcon} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M12.834 2.33301C18.63 2.33301 23.334 7.03701 23.334 12.833C23.334 18.629 18.63 23.333 12.834 23.333C7.03798 23.333 2.33398 18.629 2.33398 12.833C2.33398 7.03701 7.03798 2.33301 12.834 2.33301ZM12.834 20.9997C17.3455 20.9997 21.0007 17.3445 21.0007 12.833C21.0007 8.32034 17.3455 4.66634 12.834 4.66634C8.32132 4.66634 4.66732 8.32034 4.66732 12.833C4.66732 17.3445 8.32132 20.9997 12.834 20.9997ZM22.7332 21.0825L26.0337 24.3818L24.3828 26.0327L21.0835 22.7322L22.7332 21.0825Z"
								fill="black"
							/>
						</svg>
					</button>
				</div>
			</div>
			<div className={styles.recommendedContainer}>
				{isMobile ? null : <p>Recommend For you</p>}
				{isMobile ? (
					<div className={styles.TagContainer}>
						<div className={styles.btnContainer}>
							<button
								type="button"
								style={{ background: Travel ? "#3A0DBB" : "", color: Travel ? "#fff" : "" }}
								onClick={() => setTravel(!Travel)}>
								Travel
							</button>
							<button
								type="button"
								style={{ background: Funny ? "#3A0DBB" : "", color: Funny ? "#fff" : "" }}
								onClick={() => setFunny(!Funny)}>
								Funny
							</button>
							<button
								type="button"
								style={{ background: Photography ? "#3A0DBB" : "", color: Photography ? "#fff" : "" }}
								onClick={() => setPhotography(!Photography)}>
								Photography
							</button>
							<button
								type="button"
								style={{ background: News ? "#3A0DBB" : "", color: News ? "#fff" : "" }}
								onClick={() => setNews(!News)}>
								News
							</button>
							<button
								type="button"
								style={{ background: Pets ? "#3A0DBB" : "", color: Pets ? "#fff" : "" }}
								onClick={() => setPets(!Pets)}>
								Pets
							</button>
							<button
								type="button"
								style={{ background: RoadTrip ? "#3A0DBB" : "", color: RoadTrip ? "#fff" : "" }}
								onClick={() => setRoadTrip(!RoadTrip)}>
								RoadTrip
							</button>
						</div>
					</div>
				) : null}

				<div className={styles.recommendedDiv}>
					{/* FIXME: Start */}
					<div className={styles.HorizontalScroll}>
						<img src={EP1} alt="" />
						<img src={EP2} alt="" />
						<img src={EP3} alt="" />
						<img src={EP1} alt="" />
						<img src={EP2} alt="" />
						<img src={EP3} alt="" />
					</div>
					{/* FIXME: End */}
				</div>
			</div>

			<div className={styles.ExploreImageMainContainer}>
				{isMobile ? null : <h3>Explore</h3>}
				<div className={styles.ExploreImageContainer}>
					<img src={EP4} alt="" className={styles.row2} />
					<img src={EP4} alt="" className={styles.row21} />
					<img src={EP5} alt="" />
					<img src={EP6} alt="" />
					<img src={EP7} alt="" />
					<img src={EP5} alt="" />
					<img src={EP6} alt="" />
					<img src={EP7} alt="" />
					<img src={EP5} alt="" />
					<img src={EP6} alt="" />
					<img src={EP7} alt="" />
					<img src={EP5} alt="" />
					<img src={EP6} alt="" />
				</div>
			</div>
		</>
	);
};

export default ExploreYourWorld;
