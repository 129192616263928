import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.png";
import Notification from "./Notification";
import styles from "./SmallHeader.module.scss";
import InfoButton from "../Info/InfoButton";
import { Link } from "react-router-dom";
import bell from "../../assets/images/Bell.png"

const SmallHeader = () => {
	const [showNotification, setShowNotification] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const { notificationsCount } = useSelector((state) => state.notification);

	const [isMobile, setIsMobile] = useState(window.innerWidth < 431);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 431);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className={styles.SmallHeader}>
			{showNotification && <Notification setShowNotification={setShowNotification} />}
			{isMobile ? (
				<div className={styles.logoContainer}>
					<Link to="/home">
						<img src={logo} alt="logo" />
					</Link>
				</div>
			) : null}
			<div className={styles["SmallHeader-container"]}>
				<button type="button" className={styles.btn} onClick={() => setShowNotification(!showNotification)}>
					{notificationsCount > 0 && <div className={styles.redDot}>{notificationsCount}</div>}
					<img src={bell} className={styles.NotificationIcon} alt="" />
				</button>

				{isMobile ? null : (
					<div className={styles.SearchContainer}>
						<input type="text" placeholder="Search..." className={showSearch ? styles.active : ""} />
						<button type="button" className={styles.btn} onClick={() => setShowSearch(!showSearch)}>
							<svg className={styles.SearchIcon} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M12.834 2.33301C18.63 2.33301 23.334 7.03701 23.334 12.833C23.334 18.629 18.63 23.333 12.834 23.333C7.03798 23.333 2.33398 18.629 2.33398 12.833C2.33398 7.03701 7.03798 2.33301 12.834 2.33301ZM12.834 20.9997C17.3455 20.9997 21.0007 17.3445 21.0007 12.833C21.0007 8.32034 17.3455 4.66634 12.834 4.66634C8.32132 4.66634 4.66732 8.32034 4.66732 12.833C4.66732 17.3445 8.32132 20.9997 12.834 20.9997ZM22.7332 21.0825L26.0337 24.3818L24.3828 26.0327L21.0835 22.7322L22.7332 21.0825Z"
									fill="black"
								/>
							</svg>
						</button>
					</div>
				)}

				<InfoButton />

				<button
					type="button"
					className={styles.logo}
					onClick={() => toast.info("E-Commerce")}
					style={{ display: "none" }}>
					<img src={logo} alt="logo" />
				</button>
			</div>
		</div>
	);
};

export default SmallHeader;
