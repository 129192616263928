import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import arrow from "../../assets/svg/arrowRightNew.svg";
import axios from "../../components/Hooks/axios";
import SelectedImage from "./SelectedImage";
import styles from "./StoryWrapper.module.scss";
import TextBox from "./TextBox";
import WebcamCapture from "./WebcamCapture";
import { CropEasy } from "./crop/CropEasy";

const StoryUploadButtons = ({ setStoryPopup, setOwnRel }) => {
	const { userId } = useSelector((state) => state.auth);
	const [openCrop, setOpenCrop] = useState(false);
	const [photoURL, setPhotoURL] = useState("");
	const [textBox, setTextBox] = useState(false);
	const [openWebCame, setOpenWebCame] = useState(false);
	const [selectedImageShow, setSelectedImageShow] = useState(false);
	const [imagesArray, setImagesArray] = useState([]);
	const image = useRef();
	const [btnDisable, setBtnDisable] = useState(false);
	const [uploadPer, setUploadPer] = useState(0);

	const handleFileInputChange = (e) => {
		const file = e.target.files;
		if (file) {
			if (file[0].type === "video/mp4") {
				setBtnDisable(true);
				const id = toast.loading("Story Uploading...");
				const formData = new FormData();
				formData.append("userId", userId);
				formData.append("mediaType", "video");
				formData.append("mediaUrl", file[0]);

				axios
					.post("/story", formData, {
						onUploadProgress: (progressEvent) => {
							const progress = (progressEvent.loaded / progressEvent.total) * 100;
							setUploadPer(progress);
						},
					})
					.then(({ data }) => {
						console.log(data);
						setBtnDisable(false);
						toast.update(id, { render: data.message, type: "success", isLoading: false, autoClose: 2000 });
						setOwnRel(Math.random());
						setStoryPopup(false);
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				setSelectedImageShow(true);
				setImagesArray(Array(...file));
			}
		}

		e.target.value = null;
	};

	const Controls = () => {
		return (
			<div className={styles.Controls}>
				<h1>Update eMagz</h1>
				{isMobile ? null : (
					<>
						<h4>Drag & Drop your image or video</h4>
						<h4>Or</h4>
					</>
				)}
				<div className={styles.buttonContainer}>
					<button className={styles.button} onClick={() => setTextBox(true)}>
						Text
					</button>
					<button className={styles.button} onClick={() => toast.info("VMagz Coming Soon...")}>
						VMagz
					</button>
					{isMobile ? (
						<>
							<button className={styles.button}>Gallery</button>
							<button className={styles.button} onClick={() => setOpenWebCame(true)}>
								Camera
							</button>
						</>
					) : (
						<>
							<button className={styles.button} onClick={() => setOpenWebCame(true)}>
								Camera
							</button>
							<button
								className={styles.button}
								onClick={() => {
									if (!btnDisable) image.current.click();
								}}
								disabled={btnDisable}>
								Upload From Computer
								<span style={{ width: `${uploadPer}%` }}></span>
							</button>
						</>
					)}
				</div>
				<input type="file" multiple ref={image} style={{ display: "none" }} onChange={handleFileInputChange} />
			</div>
		);
	};

	const [isMobile, setIsMobile] = useState(window.innerWidth < 431);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 431);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className={styles.StoryUploadButtons}>
			<div className={styles.PopupCloseWrapper}>
				<div
					className={styles.PopupClose}
					onClick={() => {
						if (textBox) {
							setTextBox(false);
						} else if (selectedImageShow) {
							setSelectedImageShow(false);
						} else if (openCrop) {
							setOpenCrop(false);
							setSelectedImageShow(true);
						} else setStoryPopup(false);
					}}>
					<img src={arrow} alt="" />
				</div>
			</div>

			<div className={styles.PopupContainer}>
				{!textBox && !selectedImageShow && !openCrop && <Controls />}
				{textBox && <TextBox setStoryPopup={setStoryPopup} setOwnRel={setOwnRel} />}
				{openWebCame && (
					<WebcamCapture setOpenWebCame={setOpenWebCame} setImageArray={setImagesArray} setOpenCrop={setOpenCrop} />
				)}
				{selectedImageShow && (
					<SelectedImage
						imageArray={imagesArray}
						setImageArray={setImagesArray}
						setSelectedImageShow={setSelectedImageShow}
						setOpenCrop={setOpenCrop}
					/>
				)}
				{openCrop && (
					<CropEasy {...{ imagesArray, setImagesArray, setOpenCrop, setSelectedImageShow, setStoryPopup, setOwnRel }} />
				)}
			</div>
		</div>
	);
};

export default StoryUploadButtons;
