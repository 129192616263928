import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import Testing from "./Testing";
import { clearCacheData } from "./components/Hooks/clearCacheData";
import Page404 from "./components/Page404";
import ViewPost from "./components/PostView/ViewPost";
import SharePopup from "./components/SharePopup/SharePopup";
import ViewStory from "./components/StoryView/ViewStory";
import Ads from "./pages/ADS/Dashboard/Ads";
import UsersReviews from "./pages/ADS/UsersReviews/UsersReviews";
import YourAds from "./pages/ADS/YourAds/YourAds";
import { Chat } from "./pages/Chat/Chat";
import ChoseTemplate from "./pages/ChoseTemplate/ChoseTemplate";
import PersonaRedirect from "./pages/ChoseTemplate/PersonaRedirect";
import Explore from "./pages/Explore/Explore";
import Congratulations from "./pages/Get Started/Common Screen/Congratulations/Congratulations";
import GetStarted1 from "./pages/Get Started/Common Screen/Get Started/GetStarted1";
import Interest from "./pages/Get Started/Common Screen/Interest/Interest";
import Persona from "./pages/Get Started/Personal/Persona";
import ProfessionalPersona from "./pages/Get Started/Professional/ProfessionalPersona";
import Home from "./pages/Home/Home";
import UploadPost from "./pages/Home/UploadPost";
import AudienceInsights from "./pages/Insights/AudienceInsights";
import BusinessInsights from "./pages/Insights/BusinessInsights";
import PersonalInsights from "./pages/Insights/PersonalInsights";
import ProfileVisit from "./pages/Insights/ProfileVisit";
import ChangePassword from "./pages/Loading/Change Password/ChangePassword";
import ForgetPassword from "./pages/Loading/Forget Password/ForgetPassword";
import LoadingScreen from "./pages/Loading/Loading Screen/LoadingScreen";
import SignIn from "./pages/Loading/Sign In/SignIn";
import SignUp from "./pages/Loading/Sign Up/SignUp";
import Verification from "./pages/Loading/Verification/Verification";
import EditPost from "./pages/Post/EditPost";
import PostIndex from "./pages/Post/PostIndex";
import Security from "./pages/Security/Security";
import BlockedUser from "./pages/Setting/BlockedUser";
import FollowList from "./pages/Setting/FollowList";
import Privacy from "./pages/Setting/Privacy";
import Setting from "./pages/Setting/Setting";
import PrivacyPolicy from "./pages/Static/PrivacyPolicy";
import Terms from "./pages/Static/Terms";
import Support from "./pages/Support/Support";
import { fetchUserAccount } from "./redux/slices/accountSlice";
import { fetchDataFromLocalStorage } from "./redux/slices/authSlice";
import { fetchNotificationHistory, fetchNotifications } from "./redux/slices/notificationSlice";
import MobileSignUp from "./pages/Loading/Sign Up/MobileSignUp";
import MobileSignIn from "./pages/Loading/Sign In/MobileSignIn";
import MobileForgetPassword from "./pages/Loading/Forget Password/MobileForgetPassword";
import MobileVerification from "./pages/Loading/Verification/MobileVerification.jsx";
import MobileChangePassword from "./pages/Loading/Change Password/MobileChangePassword.jsx";

export const socket = io(`${process.env.REACT_APP_BASE_API_URL}/socket_connection`);
// export const socket = io(`http://localhost:3000/socket_connection`);

function App() {
	const dispatch = useDispatch();
	const { reload } = useSelector((state) => state.notification);
	const { userId, user } = useSelector((state) => state.auth);
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const toastId = useRef(null);
	const {
		share: { show },
	} = useSelector((state) => state.temp);

	useEffect(() => {
		const handleStatusChange = () => setIsOnline(navigator.onLine);

		window.addEventListener("online", handleStatusChange);
		window.addEventListener("offline", handleStatusChange);

		if (!isOnline)
			toastId.current = toast.error("You are Offline !!", {
				position: "top-center",
				autoClose: false,
				closeOnClick: true,
			});
		else toast.dismiss(toastId.current);

		return () => {
			window.removeEventListener("online", handleStatusChange);
			window.removeEventListener("offline", handleStatusChange);
		};
	}, [isOnline]);

	useEffect(() => {
		dispatch(fetchUserAccount());
		dispatch(fetchDataFromLocalStorage());
	}, []);

	useEffect(() => {
		if (userId === "") return;
		if (user?.is_active === true) socket.emit("joinLoginRoom", { userId });
		else socket.emit("joinLoginRoom", { userId, activeStatus: false });
	}, [socket, userId, user?.is_active]);

	useEffect(() => {
		if (userId === "") return;
		socket.emit("notificationHistory", userId);
		socket.on(`notificationHistory_${userId}`, (payload) => {
			dispatch(fetchNotificationHistory(payload));
		});
	}, [userId, reload]);

	useEffect(() => {
		if (userId === "") return;
		socket.on(`notifications_${userId}`, (payload) => {
			dispatch(fetchNotifications(payload));
		});
	}, [userId, socket]);

	clearCacheData();

	const [isMobile, setIsMobile] = useState(window.innerWidth < 431);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 431);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);


	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				limit={4}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover={false}
				theme="colored"
			/>

			{/* NOTE: Share Popup 👇 */}
			{show && <SharePopup />}

			<BrowserRouter>
				<Routes>
					<Route element={<AnalyticWrapper />}>
						<Route path="*" element={<Page404 />} />
						<Route path="/" element={isMobile ? <MobileSignIn /> : <LoadingScreen />} />
						<Route path="/signup" element={isMobile ? <MobileSignUp /> : <SignUp />} />
						<Route path="/signin" element={isMobile ? <MobileSignIn /> : <SignIn />} />
						<Route path="/forget-password" element={isMobile? <MobileForgetPassword/> : <ForgetPassword />} />
						<Route path="/verification-code" element={isMobile? <MobileVerification/> : <Verification />} />
						<Route path="/change-password" element={isMobile? <MobileChangePassword/> : <ChangePassword />} />
						<Route path="/interest" element={<Interest />} />
						<Route path="/get-started" element={<GetStarted1 />} />
						<Route path="/get-started/personal" element={<Persona />} />
						<Route path="/get-started/professional" element={<ProfessionalPersona />} />
						<Route path="/get-started/congratulation" element={<Congratulations />} />

						<Route element={<LoginWrapper />}>
							<Route path="/ChoseTemplate" element={<ChoseTemplate />} />
							<Route path="/PersonaRedirect" element={<PersonaRedirect />} />
							<Route path="/Home" element={<Home />} />
							<Route path="/Explore" element={<Explore />} />
							<Route path="/Post" element={<PostIndex />} />
							<Route path="/UploadPost" element={<UploadPost />} />
							<Route path="/EditPost/:id" element={<EditPost />} />
							<Route path="/PersonalInsights" element={<PersonalInsights />} />
							<Route path="/BusinessInsights" element={<BusinessInsights />} />
							<Route path="/AudienceInsights" element={<AudienceInsights />} />
							<Route path="/ProfileVisit" element={<ProfileVisit />} />
							<Route path="/Setting" element={<Setting />} />
							<Route path="/Security" element={<Security />} />
							<Route path="/Privacy" element={<Privacy />} />
							<Route path="/BlockedUser" element={<BlockedUser />} />
							<Route path="/FollowList" element={<FollowList />} />
							<Route path="/Chat" element={<Chat />} />
							<Route path="/Chat/:id" element={<Chat />} />
							<Route path="/Support" element={<Support />} />
							<Route path="/Post/:id" element={<ViewPost />} />
							<Route path="/Story/:id" element={<ViewStory />} />
							<Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
							<Route path="/Terms-Condition" element={<Terms />} />
							<Route path="/Ads" element={<Ads />} />
							<Route path="/UsersReviews" element={<UsersReviews />} />
							<Route path="/YourAds" element={<YourAds />} />
						</Route>
					</Route>
					<Route path="/Testing" element={<Testing />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;

const LoginWrapper = () => {
	const navigate = useNavigate();

	const userCookies = Cookies.get("E_current_userId");

	useEffect(() => {
		if (!userCookies) return navigate("/");
		// else return navigate("/");
	}, []);

	return <Outlet />;
};

const AnalyticWrapper = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: pathname, title: pathname });
	}, [pathname]);

	return <Outlet />;
};
