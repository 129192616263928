import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { toast } from "react-toastify";
import exit from "../../assets/svg/exit.svg";
import { removeUser } from "../../redux/slices/accountSlice";
import { logOut, switchAccount } from "../../redux/slices/authSlice";
import { setPostReload } from "../../redux/slices/tempSlice";
import { setCookies } from "../Hooks/setCookies";
import styles from "./Header.module.css";
import logout from "../../assets/images/whiteLogout.png";
import close from "../../assets/images/closeHeader.png";

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [LogoutPopup, setLogoutPopup] = useState(false);
	const { userId, user, token } = useSelector((state) => state.auth);
	const { users } = useSelector((state) => state.account);

	const handelLogout = () => {
		toast.success("Logout SuccessFully!!");
		setLogoutPopup(false);
		if (users.length === 1) {
			dispatch(logOut());
			navigate("/", { replace: true });
			dispatch(removeUser(users[0].userId));
		} else {
			const data = users.find((obj) => userId === obj.userId);
			const index = users.findIndex((obj) => userId === obj.userId);
			if (index === 0) {
				dispatch(switchAccount({ userId: users[1].userId, user: users[1].user, token: users[1].token }));
				dispatch(removeUser(data.userId));
			} else {
				dispatch(switchAccount({ userId: users[0].userId, user: users[0].user, token: users[0].token }));
				dispatch(removeUser(data.userId));
			}
		}
		// window.location.reload();
	};

	const [isHeaderClosed, setIsHeaderClosed] = useState(true);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 431);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 431);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const toggleHeader = () => {
		setIsHeaderClosed(!isHeaderClosed);
	};

	return (
		<div className={styles.header}>
			<nav className={`${styles.HeaderContainer} ${isHeaderClosed ? styles.closedHeader : ""}`}>
				<div className={styles.logoContainer}>
					{isMobile ? null : (
						<Link to="/home">
							<img src={logo} alt="logo" />
						</Link>
					)}
				</div>

				<div className={styles.navContainer}>
					<ul>
						<li>
							<Link to="/Home" onClick={() => dispatch(setPostReload(true))}>
								Home
							</Link>
						</li>
						<li>
							<Link
								onClick={() => {
									// if (user?.personalTemplate === "") toast.info("You didn't have a persona");
									// else
									setCookies({
										url: `${process.env.REACT_APP_TEMPLATE_BASE_URL}`,
										token,
										personaUserId: user._id,
										currentUserId: user._id,
									});
								}}>
								Persona
							</Link>
						</li>
						{isMobile? <li className={styles.update}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
								color="#ffffff"
								fill="none">
								<path
									d="M12 4V20"
									stroke="currentColor"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M4 12H20"
									stroke="currentColor"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<Link>Update</Link>
						</li>:null}
						<li>
							<Link to="/Explore">Explore</Link>
						</li>
						<li>
							<Link to="/Chat">Chat</Link>
						</li>
						<li>
							<Link to="/Setting">Setting</Link>
						</li>
						<li>
							<div className={styles.logoutContainer}>
								{isMobile ? (
									<Link onClick={() => setLogoutPopup(true)}>
										<img src={logout} alt="exit" />
									</Link>
								) : (
									<Link onClick={() => setLogoutPopup(true)}>
										<img src={exit} alt="exit" />
									</Link>
								)}
							</div>
						</li>
					</ul>
				</div>

				{/* <div className={styles.logoutContainer}>
				{isMobile ? (
					<Link onClick={() => setLogoutPopup(true)}>
						<img src={logout} alt="exit" />
					</Link>
				) : (
					<Link onClick={() => setLogoutPopup(true)}>
						<img src={exit} alt="exit" />
					</Link>
				)}
			</div> */}
			</nav>
			{LogoutPopup && (
				<div className={styles.LogoutPopup}>
					<h1>
						Do You Want To <span>End Live ?</span>
					</h1>
					<div>
						<button type="button" onClick={handelLogout}>
							Log Out
						</button>
						<button type="button" onClick={() => setLogoutPopup(false)}>
							Cancel
						</button>
					</div>
				</div>
			)}

			{isMobile ? (
				<div className={`${styles.toggleHeader} ${isHeaderClosed ? styles.closedHamburger : ""}`} onClick={toggleHeader}>
					{" "}
					<img src={close} alt="" />{" "}
				</div>
			) : null}
		</div>
	);
};

export default Header;
