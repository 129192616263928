import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import heartFill from "../../assets/svg/heartFill.svg";
import { setReload } from "../../redux/slices/notificationSlice";
import axios from "../Hooks/axios";
import styles from "./SmallHeader.module.scss";
import close from "../../assets/images/closeNotifications.png";

const Notification = ({ setShowNotification }) => {
	const { notifications } = useSelector((state) => state.notification);
	const { userId } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handelClear = () => {
		axios
			.get(`/notification/clearAll/${userId}`)
			.then(({ data }) => {
				dispatch(setReload());
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		axios
			.get(`/notification/seenAll/${userId}`)
			.then(({ data }) => {
				dispatch(setReload());
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	const [isMobile, setIsMobile] = useState(window.innerWidth < 431);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 431);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className={styles.NotificationContainerWrapper} onClick={() => setShowNotification(false)}>
			<div className={styles.NotificationContainer} onClick={(e) => e.stopPropagation()}>
				<div className={styles.NotTop}>
					<h1>Notifications</h1>

					{isMobile ? (
						<img src={close} alt="" onClick={() => setShowNotification(false)} />
					) : (
						<button type="button" onClick={handelClear}>
							Clear
						</button>
					)}
				</div>

				<div className={styles.NotMiddle}>
					{notifications.length === 0 ? (
						<h2>No Notification</h2>
					) : (
						notifications.map((data, index) => {
							const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
							const date = new Date(data.createdAt);
							let dateFormat = `${date.toLocaleTimeString().split(":")[0]}:${
								date.toLocaleTimeString().split(":")[1]
							} ${date.toLocaleTimeString().split(" ")[1]} ${
								date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
							}/${months[date.getMonth()]}`;
							return (
								<div
									key={index}
									className={`${styles.NotBox} ${data.isSeen ? "" : styles.isSeen}`}
									onClick={() => {
										if (data?.notification?.link) navigate(data?.notification?.link);
									}}>
									<div>
										<div>
											{data.title === "Like" ? (
												<img src={heartFill} alt="" />
											) : (
												<img
													src={data?.notification_from?.ProfilePic}
													alt="Profile"
													className={styles.ProfileImage}
												/>
											)}
										</div>
										<h5>
											{/* Congrats */}
											{data.title === "Like"
												? "Congrats"
												: data.title === "Follow"
												? "Congrats"
												: data.title}
											<p>{data.message}</p>
										</h5>
									</div>
									<p className={styles.timeText}>{dateFormat}</p>
								</div>
							);
						})
					)}
				</div>

				{isMobile ? (
					<div className={styles.NotBottom} onClick={handelClear}>
						<p>Clear Notifications</p>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default Notification;
