import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../../../components/Hooks/axios";
import Modal from "../../../../components/Modal/Modal";
import { setUserObject } from "../../../../redux/slices/authSlice";
import classes from "./UpdateName.module.css";

const UpdateName = ({ onClose, name }) => {
	const { userId } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [btnDisable, setBtnDisable] = useState(false);
	const [content, setContent] = useState(name);

	const handelUpdateName = () => {
		if (content === "") return toast.warn("Please fill the filed !");
		setBtnDisable(true);

		const data = JSON.stringify({
			username: content,
		});

		axios
			.put(`/auth/update-user-details?userId=${userId}`, data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				toast.success(data.message);
				dispatch(setUserObject(data.user));
				setBtnDisable(false);
				onClose(false);
			})
			.catch((response) => {
				console.log(response);
				setBtnDisable(false);
			});
	};

	return (
		<Modal onClose={onClose}>
			<div className={classes.overlay} onClick={(e) => e.stopPropagation()}>
				<div className={classes.form}>
					<h2>Update Name</h2>
					<p>Note: Once name update you can change your name for next 60 days</p>
					<label htmlFor="name">Enter your name</label>
					<input
						type="name"
						placeholder="Enter your new name"
						value={content}
						onChange={(e) => setContent(e.target.value)}
					/>
					<div className={classes.actions}>
						<button className={classes.btn} disabled={btnDisable} onClick={handelUpdateName}>
							Update
						</button>
						<button className={classes.link} onClick={() => onClose(false)}>
							Back
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default UpdateName;
