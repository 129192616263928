import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import arrow from "../../assets/images/Vector.png";
import share from "../../assets/svg/external-link-line.svg";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/Hooks/ScrollToTop";
import axios from "../../components/Hooks/axios";
import { setUserObject } from "../../redux/slices/authSlice";
import BusinessLogo from "./BusinessLogo/BusinessLogo";
import HasTags from "./HasTags/HasTags";
import Items from "./Information Items/Items";
import Items2 from "./Information Items/Items2";
import Deactivate from "./Security modal/Deactivate/Deactivate";
import Privacy from "./Security modal/Privacy/Privacy";
import TermsAndCondition from "./Security modal/Terms and condition/Terms&Condition";
import TwoWayVerification from "./Security modal/Two way verification/TwoWayVerification";
import UpdateAddress from "./Security modal/Update Address/UpdateAddress";
import Update from "./Security modal/Update Contact/Update";
import UpdateInfo from "./Security modal/Update Contact/UpdateInfo";
import UpdateDob from "./Security modal/Update DOB/UpdateDob";
import UpdateEmail from "./Security modal/Update Email/UpdateEmail";
import UpdateName from "./Security modal/Update Name/UpdateName";
import UpdateMobileNo from "./Security modal/Update mobile No/UpdateMobileNo";
import SecurityItem2 from "./Security modal/securityItem2/SecurityItem2";
import classes from "./Security.module.scss";
import SecurityItem from "./security Item/SecurityItem";
import close from "../../assets/images/closeSettings.png";

const Security = () => {
	const { user } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showName, setShowName] = useState(false);
	const [showMobileNo, setShowMobileNo] = useState(false);
	const [showDate, setShowDate] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	const [showAddress, setShowAddress] = useState(false);
	const [showTerms, setShowTerms] = useState(false);
	const [showPrivacy, setShowPrivacy] = useState(false);
	const [verification, setVerification] = useState(false);
	const [deactivate, setDeactivate] = useState(false);
	// Data
	const [name, setName] = useState("");
	const [dob, setDob] = useState("");
	const [email, setEmail] = useState("");
	const [mobile_number, setMobile_number] = useState("");
	const [address, setAddress] = useState(null);
	const [accountStatus, setAccountStatus] = useState(false);

	// Contact Update
	const [updateShow, setUpdateShow] = useState(false);
	const [iconData, setIconData] = useState({});
	const [activeUpdateData, setActiveUpdateData] = useState({});

	//Company Info
	const [activeInfo, setActiveInfo] = useState({});
	const [infoUpdateShow, setInfoUpdateShow] = useState(false);
	const [whyChooseUs, setWhyChooseUs] = useState("");
	const [businessName, setBusinessName] = useState("");
	const [businessFoundYear, setBusinessFoundYear] = useState("");
	const [businessType, setBusinessType] = useState("");
	const [businessSize, setBusinessSize] = useState("");
	const [businessSpecialty, setBusinessSpecialty] = useState("");

	//Has Tags
	const [hasTags, setHasTags] = useState([]);

	//Business Logos
	const [businessLogoData, setBusinessLogoData] = useState(null);

	const hideModalHandler = () => {
		if (setShowName(false)) return;
		if (setShowMobileNo(false)) return;
		if (setShowDate(false)) return;
		if (setShowEmail(false)) return;
		if (setShowAddress(false)) return;
		if (setShowTerms(false)) return;
		if (setShowPrivacy(false)) return;
		if (setDeactivate(false)) return;
		if (setVerification(false)) return;
	};

	useEffect(() => {
		if (user === null) return;
		setName(user?.username);
		setDob(user?.dob);
		setEmail(user?.email);
		setMobile_number(user?.mobile_number ? user?.mobile_number : "0000000000");
		setAddress(user?.address ? user?.address : "");
		setAccountStatus(user?.AccountStatus === "Active" ? false : true);
		setIconData(user?.iconData);
		setWhyChooseUs(user?.whyChoseUs);
		setBusinessName(user?.businessName);
		setBusinessFoundYear(user?.businessFoundYear);
		setBusinessType(user?.businessType);
		setBusinessSize(user?.businessSize);
		setBusinessSpecialty(user?.businessSpecialty);
		setHasTags(user?.businessHasTags);
		setBusinessLogoData(user?.companyLogos);
	}, [user]);

	let searchResult = "";
	const { search } = useLocation();
	searchResult = search.split("?about=")[1];

	const handelContactSwitch = (e, key, data) => {
		const raw = {
			[key]: {
				active: e,
				data,
			},
		};
		axios
			.put(`/auth/updateIconData`, raw)
			.then(({ data }) => {
				console.log(data);
				dispatch(setUserObject(data.data));
			})
			.catch(({ response }) => {
				console.log("Error => ", response);
			});
	};

	const [isMobile, setIsMobile] = useState(window.innerWidth < 431);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 431);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className="App">
			<div className="MainContainer">
				<ScrollToTop />
				<Header />

				<div className={classes.container}>
					{/* Personal */}
					{showName && <UpdateName onClose={setShowName} name={name} />}
					{showDate && <UpdateDob onClose={setShowDate} dob={dob} />}
					{showEmail && <UpdateEmail onClose={setShowEmail} email={email} />}
					{showMobileNo && <UpdateMobileNo onClose={setShowMobileNo} mobile_number={mobile_number} />}
					{showAddress && <UpdateAddress onClose={setShowAddress} address={address} />}

					{/* Other */}
					{showTerms && <TermsAndCondition onClose={hideModalHandler} />}
					{showPrivacy && <Privacy onClose={hideModalHandler} />}
					{verification && <TwoWayVerification onClose={hideModalHandler} />}
					{deactivate && <Deactivate onClose={setDeactivate} />}

					{/* Info */}
					{infoUpdateShow && <UpdateInfo {...{ activeInfo, setInfoUpdateShow }} />}

					{/* Contact */}
					{updateShow && <Update {...{ setUpdateShow, activeUpdateData }} />}

					<div className={classes.header}>
						{isMobile ? (
							<>
								<div className={classes.LeftContainerTop}>
									<h1 className={classes.SettingText}>Setting</h1>
									<img
										src={close}
										alt=""
										onClick={() => {
											navigate(-1);
										}}
									/>
								</div>
							</>
						) : (
							<>
								<p>Security</p>
								<button onClick={() => navigate(-1)}>
									<img src={arrow} alt="" />
								</button>
							</>
						)}
					</div>

					<div className={classes["main-content"]}>
						<section className={classes.info}>
							<h1 className={classes.heading}>Security</h1>
							<p className={classes.subheading}>Personal Information</p>
							<ul>
								<Items heading="Name" content={name} onClick={() => setShowName(true)} />
								<Items heading="Date Of Birth" content={dob} onClick={() => setShowDate(true)} />
								<Items heading="Email" content={email} onClick={() => setShowEmail(true)} />
								<Items heading="Mobile Number" content={mobile_number} onClick={() => setShowMobileNo(true)} />
								<Items
									heading="Address"
									content={
										address === ""
											? "Please add your address"
											: `${address?.addressLine1}, ${address?.addressLine2}, ${address?.city}, ${address?.district}, ${address?.state}, ${address?.pinCode}`
									}
									onClick={() => setShowAddress(true)}
								/>
							</ul>
						</section>

						{user?.accountType === "professional" && (
							<section className={classes.info}>
								<p className={classes.heading}>Company Contact Security</p>
								<ul>
									<SecurityItem2
										heading="Whatsapp"
										desc="Please enter your Whatsapp number which you want to update and use"
										keyItem="whatsapp"
										content={iconData?.whatsapp}
										setUpdateShow={setUpdateShow}
										setActiveUpdateData={setActiveUpdateData}
										action={
											<Switch
												onChange={(e) => handelContactSwitch(e, "whatsapp", iconData?.whatsapp?.data)}
												checked={iconData?.whatsapp?.active || false}
												offColor={"#D1D5FF"}
												onColor={"#909AFF"}
												offHandleColor={"#A69F9F"}
												onHandleColor={"#2E5EE2"}
												uncheckedIcon={false}
												checkedIcon={false}
												activeBoxShadow={"0 0 0px 0px #909AFF"}
												height={24}
												width={48}
											/>
										}
									/>

									<SecurityItem2
										heading="Email"
										desc="Please enter your Email id which you want to update and use"
										keyItem="email"
										content={iconData?.email}
										setUpdateShow={setUpdateShow}
										setActiveUpdateData={setActiveUpdateData}
										action={
											<Switch
												onChange={(e) => handelContactSwitch(e, "email", iconData?.email?.data)}
												checked={iconData?.email?.active || false}
												offColor={"#D1D5FF"}
												onColor={"#909AFF"}
												offHandleColor={"#A69F9F"}
												onHandleColor={"#2E5EE2"}
												uncheckedIcon={false}
												checkedIcon={false}
												activeBoxShadow={"0 0 0px 0px #909AFF"}
												height={24}
												width={48}
											/>
										}
									/>

									<SecurityItem2
										heading="Call"
										desc="Please enter your Number which you want to update and use"
										keyItem="call"
										content={iconData?.call}
										setUpdateShow={setUpdateShow}
										setActiveUpdateData={setActiveUpdateData}
										action={
											<Switch
												onChange={(e) => handelContactSwitch(e, "call", iconData?.call?.data)}
												checked={iconData?.call?.active || false}
												offColor={"#D1D5FF"}
												onColor={"#909AFF"}
												offHandleColor={"#A69F9F"}
												onHandleColor={"#2E5EE2"}
												uncheckedIcon={false}
												checkedIcon={false}
												activeBoxShadow={"0 0 0px 0px #909AFF"}
												height={24}
												width={48}
											/>
										}
									/>

									<SecurityItem2
										heading="Website link"
										desc="Please enter your Website link which you want to update and use"
										keyItem="storeUrl"
										content={iconData?.storeUrl}
										setUpdateShow={setUpdateShow}
										setActiveUpdateData={setActiveUpdateData}
										action={
											<Switch
												onChange={(e) => handelContactSwitch(e, "storeUrl", iconData?.storeUrl?.data)}
												checked={iconData?.storeUrl?.active || false}
												offColor={"#D1D5FF"}
												onColor={"#909AFF"}
												offHandleColor={"#A69F9F"}
												onHandleColor={"#2E5EE2"}
												uncheckedIcon={false}
												checkedIcon={false}
												activeBoxShadow={"0 0 0px 0px #909AFF"}
												height={24}
												width={48}
											/>
										}
									/>

									<SecurityItem2
										heading="Service area Location"
										desc="Please enter your Location which you want to update and use"
										keyItem="location"
										content={iconData?.location}
										setUpdateShow={setUpdateShow}
										setActiveUpdateData={setActiveUpdateData}
										action={
											<Switch
												onChange={(e) => handelContactSwitch(e, "location", iconData?.location?.data)}
												checked={iconData?.location?.active || false}
												offColor={"#D1D5FF"}
												onColor={"#909AFF"}
												offHandleColor={"#A69F9F"}
												onHandleColor={"#2E5EE2"}
												uncheckedIcon={false}
												checkedIcon={false}
												activeBoxShadow={"0 0 0px 0px #909AFF"}
												height={24}
												width={48}
											/>
										}
									/>
								</ul>
							</section>
						)}

						<section
							className={classes.info}
							// style={{ display: searchResult === "true" ? "none" : "" }}
						>
							<p className={classes.subheading}>Other Security</p>
							<ul>
								<SecurityItem
									className={classes.varification}
									heading="Two way Verification"
									content="Secure your account even more with Two-Way-Verification"
									action={
										<Switch
											onChange={(e) => setVerification(e)}
											checked={verification}
											offColor={"#D1D5FF"}
											onColor={"#909AFF"}
											offHandleColor={"#A69F9F"}
											onHandleColor={"#2E5EE2"}
											uncheckedIcon={false}
											checkedIcon={false}
											activeBoxShadow={"0 0 0px 0px #909AFF"}
											height={24}
											width={48}
										/>
									}
								/>
								<SecurityItem
									onClick={() => setShowPrivacy(true)}
									heading="Privacy Policy"
									content="Learn about our privacy policy"
									action={<img src={share} alt="" />}
								/>
								<SecurityItem
									onClick={() => setShowTerms(true)}
									heading="Term's of use"
									content="Learn about our terms and condition"
									action={<img src={share} alt="" />}
								/>
								<SecurityItem
									heading="Help & Support"
									content="Need help connect with our support team"
									action={
										<NavLink to="/Support">
											<img src={share} alt="" />
										</NavLink>
									}
								/>
								<SecurityItem
									className={classes.deactive}
									heading="Deactivate Account"
									content="I like to deactivate account "
									onClick={() => {
										if (accountStatus) {
											setAccountStatus(false);
										} else {
											setDeactivate(true);
										}
									}}
									action={
										<Switch
											onChange={(e) => setDeactivate(e)}
											checked={accountStatus}
											offColor={"#FF7C7C"}
											onColor={"#909AFF"}
											offHandleColor={"#FFF"}
											onHandleColor={"#2E5EE2"}
											uncheckedIcon={false}
											checkedIcon={false}
											activeBoxShadow={"0 0 0px 0px #909AFF"}
											height={24}
											width={48}
										/>
									}
								/>
							</ul>
						</section>

						{user?.accountType === "professional" && (
							<section className={classes.info}>
								<p className={classes.heading}>Company Information</p>
								<ul>
									<Items2
										heading="Company Name"
										desc="Please enter your company Name which you want to update and use"
										content={businessName}
										keyName={"businessName"}
										setInfoUpdateShow={setInfoUpdateShow}
										setActiveInfo={setActiveInfo}
									/>
									<Items2
										heading="Year Found"
										desc="Please enter your founding year which you want to update and use"
										content={businessFoundYear}
										keyName={"businessFoundYear"}
										setInfoUpdateShow={setInfoUpdateShow}
										setActiveInfo={setActiveInfo}
									/>
									<Items2
										heading="Company type"
										desc="Please enter your company which you want to update and use"
										content={businessType}
										keyName={"businessType"}
										setInfoUpdateShow={setInfoUpdateShow}
										setActiveInfo={setActiveInfo}
									/>
									<Items2
										heading="Why we choose Us ?"
										desc="Please enter your 'why we chose us ?' you want to update and use"
										choose={true}
										content={whyChooseUs}
										keyName={"whyChooseUs"}
										setInfoUpdateShow={setInfoUpdateShow}
										setActiveInfo={setActiveInfo}
									/>
									<Items2
										heading="Company size"
										desc="Please enter your company size you want to update and use"
										content={businessSize}
										keyName={"businessSize"}
										setInfoUpdateShow={setInfoUpdateShow}
										setActiveInfo={setActiveInfo}
									/>
									<Items2
										heading="Specialty of Company"
										desc="Please enter your company specialty you want to update and use"
										content={businessSpecialty}
										keyName={"businessSpecialty"}
										setInfoUpdateShow={setInfoUpdateShow}
										setActiveInfo={setActiveInfo}
									/>
								</ul>
							</section>
						)}

						{user?.accountType === "professional" && (
							<section className={classes.info}>
								<p className={classes.heading}>Hastags</p>

								<HasTags {...{ hasTags }} />
							</section>
						)}

						{user?.accountType === "professional" && (
							<section className={classes.info}>
								<p className={classes.heading}>Business Logos</p>

								<BusinessLogo {...{ businessLogoData }} />
							</section>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Security;
