import { useState, useEffect } from "react";
import Modal from "../../../../components/Modal/Modal";
import classes from "./Terms&Condition.module.css";
import logo from "../../../../assets/images/logo.png";
import close from "../../../../assets/images/closeSettings.png";

const TermsAndCondition = (props) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 431);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 431);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return (
		<div onClick={props.onClose} className={classes.outerDiv}>
			<Modal onClose={props.onClose}>
				<div className={classes.overlay}>
					{isMobile ? (
						<div className={classes.header}>
							<img src={logo} alt="" />
							<img src={close} alt="" onClick={() => props.onClose()} />
						</div>
					) : null}
					<h2>Term & condition</h2>
					<article>
						<p>
							MAGZ COSMIC PRIVATE LIMITED, the provider of "eMagz" products, develops technologies and services that
							foster connections, community building, and business growth. These Terms govern your usage of "eMagz"
							and its associated products, features, apps, services, technologies, and software, unless explicitly
							specified otherwise. Our Products are offered to you without any charges, unless stated otherwise.
							Instead, businesses, organizations, and other entities compensate us to display ads promoting their
							products and services. By using our Products, you consent to us showing you personalized ads that we
							believe are relevant to your interests. Your personal data is utilized to determine the selection of
							personalized ads presented to you.
						</p>
						<p>
							We do not sell your personal data to advertisers, nor do we share information that directly identifies
							you (such as your name, email address, or contact information) with advertisers, unless you provide
							explicit permission.
						</p>
						<p>
							Advertisers provide us with targeting criteria, such as the desired audience for their ads, and we
							display those ads to individuals who may have an interest. We offer advertisers performance reports on
							their ads, which assist them in understanding how users interact with their content.
						</p>
						<p>
							Our Privacy Policy elaborates on how we collect and utilize your personal data to determine the ads
							you see and deliver the described services. At any time, you can access the settings pages of the
							platform and its products to review and manage your privacy preferences regarding data usage.
						</p>
						<p>We are committed to protecting your privacy and providing transparency in our practices. </p>
						<p>
							In line with our mission to empower individuals and foster community, we offer products and services
							that enable you to build connections and bring the world closer together.
						</p>
						<p>
							Our platform, "eMagz," provides a personalized experience tailored uniquely to you. Through analyzing
							data such as your connections, preferences, settings, and activities both on and off our Products, we
							curate content, including posts, stories, events, ads, and other features, to match your interests and
							enhance your user experience.
						</p>
						<p>
							We aim to connect you with people, groups, businesses, organizations, and other entities that hold
							significance in your life. Leveraging data insights, we make suggestions that align with your
							preferences, such as recommending groups to join, events to attend, "eMagz" personas to follow or
							message, shows to watch, and individuals you may want to establish connections with. We believe that
							stronger ties to the people, groups, and organizations you care about contribute to more vibrant
							communities and a more fulfilling experience on our platform.
						</p>
						<p>
							With eMagz's groundbreaking features, we merge the worlds of social media and empowering users to
							design customized personas for personal and business branding. Additionally, eMagz provides a unique
							platform for users to showcase their talents and express themselves in various ways.
						</p>
						<p>
							We empower you to communicate and express yourself freely about topics that matter to you. Whether
							it's sharing status updates, photos, videos, or persona content in accordance with your settings,
							engaging in messaging or voice/video calls with friends and family, creating events or groups, or
							gaining insights on how others engage with your content, our platform offers multiple avenues for
							self-expression and meaningful communication.
						</p>
						<p>
							At "eMagz," we are committed to facilitating connections, promoting self-expression, and providing a
							platform where individuals can engage with what matters to them.
						</p>
						<p>
							To ensure a safe and accountable community, "eMagz" is available for use by individuals who are at
							least 13 years old, not convicted sex offenders, who provide accurate personal information, use their
							real name, maintain only one personal account, safeguard their password, refrain from granting access
							to others or transferring the account without permission; however, if your account has been previously
							disabled due to violations of our terms, community standards, or policies, you agree not to create
							another account without our consent, which is provided at our discretion, and does not imply that the
							disciplinary action was unwarranted; and if applicable laws prohibit you from receiving our products,
							services, or software, you are also restricted from using "eMagz."
						</p>
						<p>
							In order to prioritize the safety, well-being, and integrity of our community, on the "eMagz"
							platform, you are not allowed to engage in certain activities or share specific content, including but
							not limited to: violating our terms, community standards, or other applicable policies; engaging in
							unlawful, misleading, discriminatory, or fraudulent behavior; sharing content without the necessary
							rights or permissions; infringing upon someone else's rights, including intellectual property rights;
							uploading viruses or malicious code; accessing or collecting data without permission; selling or
							purchasing data obtained from us, except as permitted; misusing reporting, flagging, dispute, or
							appeals channels; and engaging in conduct that may harm our community, compromise our services, or
							expose us to legal liability. We have the right to remove or restrict access to violating content and
							can suspend or disable your account accordingly. We may inform you about content removal and provide
							review options, unless there are serious or repeated violations, potential legal implications,
							technical limitations, or legal prohibitions. We encourage you to report any violations or misuse of
							our platform and reserve the right to remove or restrict access to content, features, services, or
							information to prevent misuse or mitigate legal or regulatory consequences to "eMagz."
						</p>
						<p>
							In order to provide our services on the "eMagz" platform, we require certain permissions from you, as
							outlined below:
						</p>
						<ol>
							<li>
								Permission to use content you create and share: You retain ownership of the intellectual property
								rights in the content you create and share on "eMagz." However, to provide our services, we
								require a license from you to use this content. This license allows us to host, distribute,
								modify, copy, publicly perform or display, translate, and create derivative works of your content.
								For example, if you share a photo on "eMagz," we have permission to store, copy, and share it with
								others, including our service providers. This license will expire once your content is deleted
								from our systems.
							</li>
							<li>
								Content deletion and retention: You have the ability to delete individual content you share at any
								time, and if you delete your account, all content posted on your personal account will be deleted
								as well. However, it may take up to 90 days for the content to be completely deleted from our
								backups and disaster recovery systems. There are certain situations where content may not be
								immediately deleted, such as when others have used your content in accordance with the license or
								when deletion is not technically feasible. We may also retain content to investigate illegal
								activities, protect the safety and security of our platform and users, comply with legal
								obligations, or respond to requests from authorities.
							</li>
							<li>
								Permission to use your name, profile picture, and actions with ads: You grant us permission to use
								your name, profile picture, and information about your actions on "eMagz" for displaying ads,
								offers, and sponsored or commercial content on our platform. This includes showing your friends
								that you have interacted with advertised events or brands. Only users who have permission to see
								your actions can view these ads and content. You have control over your ad settings and
								preferences.
							</li>
							<li>
								Permission to update software: If you download or use our software, you give us permission to
								download and install updates to the software when available. This ensures that you have the latest
								features and improvements.
							</li>
						</ol>
						<p style={{ marginTop: "2rem" }}>
							By providing these permissions, you enable us to deliver and enhance our services on the "eMagz"
							platform.
						</p>
						<div className={classes.padding}>
							<p>
								If you utilize content provided by us on the "eMagz" platform that is protected by intellectual
								property rights, such as images, designs, videos, or sounds, we retain full ownership of that
								content. You are only allowed to use our copyrights or trademarks in accordance with our Brand
								Usage Guidelines or with our explicit written permission. Any modification, translation, creation
								of derivative works, decompiling, reverse-engineering, or attempts to extract source code from our
								products or components require our written permission or must be in compliance with an open-source
								license, unless exceptions or limitations are specified by applicable law.
							</p>
							<p>
								In order to enhance our services, introduce new features, ensure a safe experience, and comply
								with relevant laws, we may periodically update our Terms. These updates will accurately reflect
								the functioning of our platform and take into account your interests. Changes will be made if the
								existing provisions are no longer suitable, incomplete, necessary for safety and security, or
								required by law. We will provide you with advance notice of at least 30 days (via email or within
								our Products) before implementing any changes to the Terms, allowing you to review them prior to
								their effective date, unless immediate changes are mandated by law. By continuing to use our
								platform after the updated Terms have taken effect, you will be bound by them. While we hope you
								choose to remain an active user of our platform, if you do not agree to the updated Terms, you
								have the option to delete your account at any time.
							</p>
							<p>
								In order to maintain a welcoming and safe environment for our users, we reserve the right to
								suspend or permanently disable access to our platform and to permanently disable or delete
								accounts if, in our discretion, it is determined that there have been clear, serious, or repeated
								breaches of our Terms or Policies, including the Community Standards. Account suspension or
								deletion may also occur in cases of repeated infringement of intellectual property rights of
								others or as required by law. If your account remains unconfirmed, is inactive for an extended
								period, or if there is suspicion of unauthorized use, we may disable or delete your account. We
								will inform you of such actions taken and provide an explanation, unless it could lead to legal
								liability, harm the community, compromise our services or systems, or if technical limitations or
								legal restrictions prevent us from doing so. If your account has been disabled or deleted, you can
								find more information on the actions you can take and how to contact us if you believe your
								account was disabled in error. Upon deletion or disabling of your account, these Terms will no
								longer apply as an agreement between you and us.
							</p>
							<p>
								While we strive to provide the best possible experience on our platform and establish clear
								guidelines for users, we cannot guarantee that our Products will always be completely safe,
								secure, error-free, or uninterrupted. We provide our platform "As is" and disclaim all warranties,
								both express and implied, including warranties of merchantability, fitness for a particular
								purpose, title, and non-infringement, to the extent permitted by law. We do not have control over
								the actions or statements of individuals, and we are not responsible for their conduct, whether
								online or offline, or for any content they share, including offensive, inappropriate, obscene,
								unlawful, or objectionable content. Since issues can arise unexpectedly, our liability is limited
								to the fullest extent permitted by applicable law. Under no circumstances will we be liable to you
								for any consequential, special, indirect, exemplary, punitive, or incidental damages, or for any
								lost profits, revenues, information, or data, arising from or related to these Terms or the
								platform, even if we have been advised of the possibility of such damages. This limitation of
								liability applies regardless of the theory of liability, including negligence.
							</p>
							<p>
								In the event of disputes, if you are a consumer, the laws of your country of residence will apply
								to any claim or dispute related to the platform's terms, and you may resolve it in a competent
								court in your country. For all other cases and any claim initiated by the platform against you,
								both parties agree that it must be exclusively resolved in an Indian court, and you consent to the
								personal jurisdiction of these courts. The terms will be governed by Indian laws, regardless of
								conflict of law provisions, and "eMagz" (MAGZ COSMIC PRIVATE LIMITED) reserves the right to bring
								any claim against you in a competent court in your country of residence.
							</p>
							<p>
								These Terms, which replace the previously known Statement of Rights and Responsibilities,
								represent the entire agreement between you and "eMagz Platform" (MAGZ COSMIC PRIVATE LIMITED)
								concerning your use of the platform, and they override any prior agreements. Additionally, certain
								Products may have supplementary Terms that apply to them, and if you use those Products, the
								supplemental Terms will become part of our agreement with you. In the event of any conflict
								between the supplemental Terms and these Terms, the supplemental Terms will prevail. If any part
								of these Terms is deemed unenforceable, it will be modified to the minimum extent necessary to
								make it enforceable. If it cannot be made enforceable, the unenforceable portion will be severed,
								and the remaining part will continue to be in effect. Our failure to enforce any of these Terms
								does not constitute a waiver. Any changes or waivers to these Terms must be in writing and signed
								by us. You are not permitted to transfer your rights or obligations under these Terms to anyone
								else without our consent. If your account is memorialized, you can designate a person as a legacy
								contact to manage it. Only your legacy contact or a person identified in a valid will or similar
								legal document expressing consent will have limited access to information from your account after
								it is memorialized, provided you have enabled this feature in your settings. These Terms do not
								grant any rights to third-party beneficiaries. We have the freedom to assign our rights and
								obligations under these Terms freely, whether through a merger, acquisition, sale of assets,
								operation of law, or other means. In certain circumstances, we may change your account's username
								(e.g., if someone else claims the username and it appears unrelated to your real-life name). We
								value your feedback and suggestions regarding our products and services, but we are not obligated
								to compensate you for them, and we may use them without restrictions or the need to keep them
								confidential. We retain all rights that are not explicitly granted to you under these Terms.
							</p>
						</div>

						<p>
							Our platform serves as a global hub where individuals come together to share their experiences,
							connect with loved ones, and establish communities. It provides a space for people to freely express
							themselves, transcending geographical and cultural boundaries, and supporting communication in
							multiple languages.
						</p>
						<p>
							We understand the significance of our role in maintaining a safe environment that empowers users to
							communicate effectively. To ensure this, we have developed a set of standards that outline what is
							permissible and what is not on our platform.
						</p>
						<p>
							These standards are not arbitrarily set but are derived from valuable feedback from our users and
							insights from experts in various fields such as technology, public safety, and human rights. We take
							great care to consider diverse perspectives and beliefs when creating these standards, with a
							particular focus on including voices that may otherwise be overlooked or marginalized.
						</p>
						<p>
							The ultimate goal of our platform is to facilitate open expression and provide people with a voice. We
							want individuals to feel comfortable discussing topics that matter to them, whether through written
							comments, photos, music, or other artistic forms, even if their viewpoints may be met with
							disagreement or objection. In certain instances, we may permit content that would typically violate
							our standards if it holds newsworthiness and public interest. However, this decision is made after a
							careful evaluation of the potential harm against the public interest value, and we refer to
							international human rights standards to guide these judgments. Furthermore, we may remove content that
							employs ambiguous or implicit language when additional context enables us to reasonably conclude that
							it goes against our standards.
						</p>
						<p>
							Authenticity is a fundamental aspect we prioritize on our platform. We want to ensure that the content
							users encounter is genuine. We believe that authenticity fosters a better sharing environment, which
							is why we prohibit individuals from misrepresenting their identities or activities on our platform.
						</p>
						<p>
							Safety is of paramount importance to us, and we strive to make our platform a secure space.
							Consequently, we remove content that may contribute to physical harm or pose a risk to the well-being
							of individuals. We recognize that content with threatening behaviour has the potential to intimidate,
							exclude, or silence others, and we do not tolerate such content.
						</p>
						<p>
							Respecting personal privacy and safeguarding information are core principles we adhere to. We are
							committed to protecting the privacy of our users, as it grants them the freedom to express themselves,
							choose when and how to share on our platform, and establish connections more easily.
						</p>
						<p>
							We firmly believe in the equality of all individuals and their inherent dignity and rights.
							Consequently, we expect users to treat others with respect and refrain from engaging in harassing or
							degrading behaviour.
						</p>
						<p>
							Our Community Standards apply universally to all users worldwide and cover all types of content. These
							standards serve as a guideline to ensure that our platform remains a safe, inclusive, and vibrant
							space for individuals to express themselves, share their stories, and connect with others.
						</p>

						<ul>
							<li className={classes.li}>Policy Rationale:</li>
							<p>
								Our platform has implemented a comprehensive set of policies to prevent potential offline harm
								that may arise from content shared on our platform. While we recognize that people often express
								disagreement or disdain by making non-serious threats or calling for violence, we strictly remove
								language that incites or facilitates serious violence. In cases where we believe there is a
								genuine risk of physical harm or direct threats to public safety, we take action by removing
								content, disabling accounts, and cooperating with law enforcement. We also consider the language
								and context of the content to differentiate between casual statements and content that poses a
								credible threat to personal or public safety. When evaluating the credibility of a threat, we may
								take into account additional information such as a person's public visibility and the risks to
								their physical well-being.
							</p>
							<p>
								In some instances, we come across aspirational or conditional threats directed towards terrorists
								or other violent actors (e.g., "Terrorists deserve to be killed"), which we generally consider
								non-credible unless specific evidence suggests otherwise.
							</p>
							<li className={classes.li}>Our policy prohibits the following types of content:</li>
							<ol>
								<p>
									1. Threats of high-severity violence, including death or other forms of serious violence, and
									admissions of past violence targeting individuals or specific locations. This includes
									statements of intent to commit violence, calls for violence, statements advocating violence,
									aspirational or conditional statements to commit violence, and admissions of committing
									violence.
								</p>
								<p>
									2. Content related to kidnapping or abduction, including statements of intent, advocacy, calls
									to action, aspirational or conditional statements, or content that promotes, supports, or
									advocates for such actions. However, content depicting kidnappings or abductions is allowed if
									it is shared by victims or their families seeking help, or for informational, condemnation, or
									awareness-raising purposes.
								</p>
								<p>
									3. Threats of violence leading to serious injury (mid-severity violence) and admissions of
									past violence towards private individuals, unnamed specified persons, minor public figures,
									high-risk persons, or high-risk groups. This includes statements of intent to commit violence,
									statements advocating violence, calls for violence, aspirational or conditional statements to
									commit violence, and admissions of committing violence, except in certain contexts such as
									redemption, self-defence, fight-sports, or when committed by law enforcement, military, or
									state security personnel.
								</p>
								<p>
									4. Content involving violence towards targets other than private individuals, minor public
									figures, high-risk persons, or high-risk groups, where the threat is defined as statements of
									intent to commit violence, calls for action of violence, statements advocating violence, or
									aspirational or conditional statements to commit violence.
								</p>
								<p>
									5. Threats of physical harm or lower-severity violence towards private individuals or minor
									public figures, requiring self-reporting. This includes statements of intent or advocacy,
									calls to action, aspirational or conditional statements to commit low-severity violence.
									Additionally, instructions on making or using weapons are only allowed if it can be
									demonstrated that the purpose is non-violent, such as in commercial video games,
									scientific/educational contexts, or for recreational self-defense.
								</p>
								<p>
									6. Statements, calls to action, or aspirational or conditional statements advocating violence
									related to voting, voter registration, or the administration or outcome of an election.
								</p>
								<p>
									7. Statements, calls to action, or aspirational or conditional statements encouraging or
									inciting armed presence at locations, such as places of worship, educational facilities,
									polling places, or locations associated with temporary signs of heightened violence or harm.
									This includes visuals representing violence targeting such locations.
								</p>
								<p>
									8. Statements, calls to action, or aspirational or conditional statements advocating for
									forcibly entering locations associated with temporary signs of heightened violence or harm,
									such as places of worship, educational facilities, polling places, or locations where violence
									occurred in recent protests.
								</p>
							</ol>
							<p>
								For some of the Community Standards mentioned above, we may require additional information or
								context to enforce them. These include violent threats against law enforcement officers, threats
								against people accused of a crime, coded statements with veiled threats, and threats against
								election workers or claims of election-related wrongdoing when combined with signals of violence
								or additional contextual confirmation.
							</p>
							<p>
								Implicit statements of intent or advocacy, calls to action, or aspirational or conditional
								statements to bring armaments to locations, including places of worship, educational facilities,
								polling places, or locations used to count votes or administer an election are also subject to
								restrictions. We may restrict calls to bring armaments to certain locations with temporary signals
								of heightened risk or violence, such as planned protests or recent protest-related violence in the
								same city.
							</p>
							<p>
								By implementing these policies, we aim to create a safer online environment, minimize potential
								harm, and promote responsible and respectful online interactions.
							</p>

							<li className={classes.li}>Prohibited Items:</li>
							<ol>
								<p>
									1. Firearms: Content that involves attempting to buy, sell, trade, or ask for firearms,
									firearm parts, ammunition, explosives, or lethal enhancements is not allowed. Exceptions are
									made for legitimate brick-and-mortar entities, including retail businesses, websites, brands,
									or government agencies posting on their behalf, or for certain purposes such as donating,
									trading, or buying back firearms and ammunition.
								</p>
								<p>
									2. Non-Medical Drugs: Content related to non-medical drugs, including attempts to buy, sell,
									trade, donate, or ask for such drugs, is prohibited. This also includes admissions of personal
									usage without acknowledging recovery or treatment, coordination or promotion of non-medical
									drug use, and providing instructions for making or using non-medical drugs.
								</p>
								<p>
									3. Pharmaceutical Drugs: Attempting to buy, sell, trade, or ask for pharmaceutical drugs is
									not allowed, except for specific circumstances such as discussing the affordability,
									accessibility, or efficacy of pharmaceutical drugs in a medical context.
								</p>
								<p>
									4. Marijuana: Content that involves attempting to buy, sell, trade, donate, or ask for
									marijuana is prohibited.
								</p>
								<p>
									5. Endangered Species: It is not allowed to buy, sell, trade, donate, or ask for endangered
									species or their parts. Admissions of poaching, buying, or trading of endangered species are
									also prohibited, except for depictions of poaching by strangers.
								</p>
								<p>
									6. Live Non-Endangered Animals: Attempting to buy, sell, or trade live non-endangered animals
									is generally prohibited, with exceptions made for legitimate brick-and-mortar entities and
									cases related to donating or rehoming animals.
								</p>
								<p>
									7. Human Blood: Content attempting to buy, sell, trade, or ask for human blood is not allowed,
									except in the context of donation or gifting.
								</p>
								<p>
									8. Alcohol/Tobacco: Attempts to buy, sell, trade, or ask for alcohol or tobacco are generally
									prohibited, with exceptions made for legitimate brick-and-mortar entities or in cases where
									the consumption occurs at specific locations or events.
								</p>
								<p>
									9. Weight Loss Products: Content involving weight loss products that contain miracle claims
									and attempts to buy, sell, trade, donate, or gift such products are prohibited.
								</p>
								<p>
									10. Historical Artifacts: Attempts to buy, sell, trade, donate, or ask for historical
									artifacts are not allowed.
								</p>
								<p>
									11. Entheogens: Content involving attempts to buy, sell, trade, donate, or ask for entheogens
									is prohibited, with the exception of discussions related to their legality or scientific and
									medical merits.
								</p>
								<p>
									12. Hazardous Goods and Materials: Content attempting to buy, sell, trade, donate, or ask for
									hazardous goods and materials is not allowed, unless it occurs within a fictional or
									documentary context.
								</p>
							</ol>
							<p>
								It is important to adhere to these guidelines to ensure the safety and compliance of our platform.
							</p>
							<p>
								These terms and conditions outline the guidelines for content that cannot be posted or shared on
								our platform ("eMagz"). By accessing or using the Platform, you agree to comply with these terms
								and conditions and ensure that any content you post adheres to the guidelines outlined below.
								Failure to comply may result in the removal of your content, suspension, or termination of your
								account.
							</p>
							<ol>
								<li>
									Violence and Criminal Behavior: Users are strictly prohibited from posting or promoting
									content that incites violence, encourages criminal behavior, or promotes dangerous
									organizations or individuals. This includes, but is not limited to, content that glorifies
									violence, provides instructions for carrying out illegal activities, or advocates harm towards
									others.
								</li>
								<li>
									Coordinating Harm and Promoting Crime: Content that aims to coordinate or plan harmful
									activities, including but not limited to acts of terrorism, drug trafficking, or organized
									crime, is strictly prohibited on the Platform.
								</li>
								<li>
									Restricted Goods and Services: Users are prohibited from posting content related to the sale,
									promotion, or facilitation of restricted goods and services, such as illegal drugs, weapons,
									counterfeit products, or items that violate applicable laws and regulations.
								</li>
								<li>
									Fraud and Deception: Content that engages in fraudulent or deceptive practices, such as scams,
									phishing attempts, or pyramid schemes, is strictly prohibited on the Platform.
								</li>
								<li>
									Safety: a. Suicide and Self-Injury: Any content that promotes or glorifies self-harm, suicide,
									or encourages others to engage in such behaviors is strictly prohibited. b. Child Sexual
									Exploitation, Abuse, and Nudity: Content that exploits or harms children, including child
									pornography, child abuse, or nudity involving minors, is strictly prohibited. c. Adult Sexual
									Exploitation: Content that involves non-consensual sexual acts, human trafficking, or the
									sexual exploitation of adults is strictly prohibited. d. Bullying and Harassment: Users are
									prohibited from posting content that harasses, threatens, or bullies individuals, including
									but not limited to hate speech, personal attacks, or stalking. e. Human Exploitation: Content
									that promotes or glorifies human trafficking, slavery, or any form of exploitation is strictly
									prohibited. f. Privacy Violations: Users must not post content that violates the privacy
									rights of individuals, including sharing personal information without consent or engaging in
									doxxing. g. Objectionable Content: Content that is deemed offensive, obscene, or morally
									objectionable, including hate speech, discriminatory remarks, or graphic violence, is strictly
									prohibited.
								</li>
								<li>
									Integrity and Authenticity: a. Account Integrity and Authentic Identity: Users must maintain
									the integrity of their accounts and must not impersonate others or misrepresent their
									identity. Posting content on behalf of others without their permission is also prohibited. b.
									Spam: Users are prohibited from posting spam content, including repetitive, misleading, or
									irrelevant messages, comments, or advertisements. c. Cybersecurity: Posting or sharing content
									that aims to compromise the cybersecurity of individuals or entities, such as viruses,
									malware, or hacking tools, is strictly prohibited. d. Inauthentic Behavior: Users must not
									engage in inauthentic behavior, such as using automated bots, fake accounts, or manipulative
									tactics to artificially increase engagement or manipulate the Platform's algorithms.
								</li>
								<li>
									Misinformation: Users are prohibited from spreading false information, conspiracy theories, or
									engaging in disinformation campaigns that can cause harm or mislead others.
								</li>
								<li>
									Memorialization: Respect for deceased individuals is essential. Users must refrain from
									posting content that is disrespectful or offensive towards the deceased or their families.
								</li>
								<li>
									Respecting Intellectual Property: Users must respect intellectual property rights and should
									not post content that infringes upon copyrights, trademarks, patents, or any other
									intellectual property rights of others.
								</li>
								<li>
									Content-Related Requests and Decisions: a. User Requests: Users can request the removal of
									specific content by reporting it through the appropriate channels provided by the Platform. b.
									Additional Protection of Minors: To provide additional protection to minors, the Platform
									reserves the right to implement additional safeguards, such as age verification mechanisms, to
									prevent minors from accessing inappropriate content.
								</li>
							</ol>
							<p>
								Please note that this list is not exhaustive, and the Platform reserves the right to remove or
								take action against any content that violates these terms and conditions, even if it is not
								explicitly mentioned. The Platform also reserves the right to modify these terms and conditions at
								any time without prior notice.
							</p>
							<li className={classes.li}>Policy for User Transaction Details:</li>
							<ol>
								<li>
									<b>User Privacy: </b>We prioritize user privacy and commit to protecting their personal
									information, including transaction details, in accordance with applicable laws and
									regulations. We will only collect, store, and use transaction details to facilitate
									transactions and improve our services.
								</li>
								<li>
									<b>Secure Transactions: </b>We employ robust security measures to safeguard user transaction
									details from unauthorized access, disclosure, alteration, or destruction. We use encryption,
									firewalls, and secure payment gateways to ensure the security of user transactions.
								</li>
								<li>
									<b>Data Retention: </b>We retain user transaction details for the minimum period required by
									law or as necessary to provide our services. Once the retention period expires, we will
									securely delete or anonymize the transaction details.
								</li>
								<li>
									<b>Third-Party Payment Processors: </b>To process transactions, we may partner with trusted
									third-party payment processors. These processors adhere to their own privacy and security
									policies, and users will be redirected to their platforms for payment processing. We encourage
									users to review the policies of these processors before proceeding with transactions.
								</li>
								<li>
									<b>Non-Disclosure: </b>We do not sell, rent, or share user transaction details with third
									parties for their marketing purposes without explicit consent. However, we may share
									transaction details with relevant authorities or law enforcement agencies when required by law
									or to prevent fraudulent activities.
								</li>
							</ol>

							<li className={classes.li}>Policy for Promotions/Ads and Boosting Services:</li>
							<ol>
								<li>
									<b>Transparency: </b>We ensure transparency in all promotional content and advertisements
									displayed on our platform. Users should clearly distinguish between organic content and
									sponsored/promoted content.
								</li>
								<li>
									<b>Advertiser Guidelines: </b>Advertisers must adhere to our guidelines regarding content
									quality, accuracy, and compliance with applicable laws and regulations. We reserve the right
									to reject or remove any promotion or ad that violates our guidelines or is deemed
									inappropriate.
								</li>
								<li>
									<b>User Control: </b>Users have the ability to control the types of promotions and ads they
									see on our platform. They can adjust their preferences, opt-out of certain categories, or
									provide feedback on irrelevant or misleading content.
								</li>
								<li>
									<b>Impartiality: </b>We maintain impartiality when displaying promotions or boosting services,
									ensuring fair and equal opportunities for all advertisers. Paid promotions or boosted content
									will not receive preferential treatment over organic content.
								</li>
								<li>
									<b>Disclosures: </b>Advertisements and sponsored content must be clearly labeled as such,
									using appropriate disclosure markers or tags. Misleading or deceptive practices to camouflage
									promotions as organic content are strictly prohibited.
								</li>
							</ol>
							<li className={classes.li}>Policy for Encrypted Communication on the Platform:</li>
							<ol>
								<li>
									<b>Encryption Standards: </b>We employ industry-standard encryption protocols to secure user
									communications on our platform. This ensures that messages and information shared between
									users remain confidential and protected from unauthorized access.
								</li>
								<li>
									<b>End-to-End Encryption: </b>Whenever feasible and legally permissible, we implement
									end-to-end encryption for user communications. This means that only the communicating parties
									can access the content, and even we cannot decrypt or read the messages.
								</li>
								<li>
									<b>Lawful Access: </b>While we prioritize user privacy and security, we also comply with legal
									requirements and cooperate with lawful requests from authorized government agencies. In such
									cases, we may provide access to encrypted communications as legally mandated.
								</li>
								<li>
									<b>User Responsibility: </b>Users are responsible for maintaining the security of their
									accounts and safeguarding their encryption keys or credentials. We encourage users to adopt
									strong passwords, enable two-factor authentication, and exercise caution when sharing
									sensitive information.
								</li>
								<li>
									<b>Abuse and Misuse: </b>Encrypted communication must not be used for illegal activities,
									harassment, hate speech, or any form of harmful behavior. We reserve the right to investigate
									and take appropriate action against users who violate our terms of service or abuse the
									encryption features.
								</li>
							</ol>
						</ul>

						<h6 className={classes.h6}>Things you should know</h6>

						<ol>
							<li>
								<b>User-Generated Content: </b>a. The platform shall not be liable for the content posted or
								generated by users on the platform. Users are responsible for their own actions, and the platform
								does not endorse or guarantee the accuracy, completeness, or legality of user-generated content.
								b. The platform may, but is not obligated to, monitor or moderate user-generated content for
								compliance with its policies and guidelines.
							</li>
							<li>
								<b>Third-Party Content and Links: </b>a. The platform may contain links to third-party websites,
								applications, or services. The platform shall not be liable for the content, accuracy, or
								availability of such third-party resources. b. Any transactions or interactions between users and
								third parties are solely the responsibility of the involved parties, and the platform shall not be
								liable for any disputes, damages, or losses arising from such transactions or interactions.
							</li>
							<li>
								<b>Privacy and Data Security: </b>a. The platform shall take reasonable measures to protect user
								privacy and secure user data. However, the platform cannot guarantee the security of user
								information or prevent unauthorized access or breaches. b. Users are responsible for safeguarding
								their own personal information and using the platform's privacy settings and security features
								appropriately.
							</li>
							<li>
								<b>Availability and Performance: </b>a. The platform strives to provide uninterrupted access and
								optimal performance. However, the platform shall not be liable for any disruptions, outages, or
								technical issues that may occur, including but not limited to server failures, network problems,
								or maintenance activities. b. The platform does not guarantee the continuous availability,
								reliability, or functionality of its services, and users acknowledge that temporary interruptions
								or limitations may occur.
							</li>
							<li>
								<b>Intellectual Property: </b>a. The platform respects intellectual property rights and endeavour
								to ensure compliance. However, the platform shall not be liable for any infringement of
								third-party intellectual property rights committed by users or other third parties. b. Users are
								responsible for ensuring that the content they post on the platform does not violate any
								intellectual property rights of others.
							</li>
							<li>
								<b>Advertisement Content: </b>a. The platform may display advertisements from third-party
								advertisers. The platform shall not be liable for the content, accuracy, or legality of such
								advertisements or for any damages or losses resulting from users' interactions or transactions
								with advertisers. b. Advertisers are solely responsible for the accuracy, legality, and compliance
								of their advertisement content.
							</li>
							<li>
								<b>User Conduct: </b>a. The platform expects users to comply with its terms of service, community
								guidelines, and applicable laws. Users shall be solely liable for their conduct, interactions, and
								content posted on the platform. b. The platform shall not be liable for any damages, losses, or
								liabilities arising from users' actions, including but not limited to defamation, infringement, or
								violation of others' rights.
							</li>
							<li>
								<b>Indemnification: </b>a. Users agree to indemnify and hold the platform and its affiliates,
								officers, directors, employees, and agents harmless from any claims, damages, liabilities, or
								expenses arising out of their use of the platform or violation of these terms.
							</li>
							<li>
								<b>Limitation of Liability: </b>a. To the fullest extent permitted by law, the platform shall not
								be liable for any direct, indirect, incidental, consequential, or exemplary damages, including but
								not limited to loss of profits, data, or reputation, arising from the use or inability to use the
								platform's services.
							</li>
							<li>
								<b>Amendments: </b>The platform reserves the right to update or modify its liabilities and
								limitations of liability at any time. Users will be provided with reasonable notice of any
								changes.
							</li>
							<li>
								<b>Governing Law and Jurisdiction: </b>These liabilities shall be governed by and construed in
								accordance with the laws of INDIAN COURT IN THE STATE OF WEST BENGAL. Any disputes arising out of
								or in connection with these liabilities shall be subject to the exclusive jurisdiction of the
								INDIAN COURT OF LAW IN THE STATE OF WEST BENGAL.
							</li>
						</ol>

						<h6 className={classes.h6}>ADVERTISING POLICY</h6>
						<ol>
							<li>
								<b>Introduction: </b>These Advertisement Policies ("Policies") outline the guidelines and
								standards for advertising on [“eMagz”] ("MAGZ COSMIC PRIVATE LIMITED"). Advertisers
								("Advertisers") must adhere to these Policies to ensure a safe and trustworthy advertising
								environment for users. By utilizing the Platform's advertising services, Advertisers agree to
								comply with these Policies.
							</li>
							<li>
								<b>Advertiser's Eligibility: </b>a. Advertisers must be legally capable of entering into
								contracts. b. Advertisers must comply with the Platform's eligibility criteria, which may include
								age restrictions, geographical limitations, and content guidelines.
							</li>
							<li>
								<b>Advertisement Content: </b>a. Advertisements must comply with all applicable laws, regulations,
								and industry standards. b. Advertisements must not contain false, misleading, or deceptive
								information. c. Advertisements must not infringe upon the intellectual property rights of third
								parties, including copyrights, trademarks, or patents. d. Advertisements must not promote or
								contain content related to illegal activities, hate speech, discrimination, violence, pornography,
								or any other offensive or objectionable material, as determined by the Platform. e. Advertisements
								must clearly and accurately represent the product or service being advertised.
							</li>
							<li>
								<b>Ad Placement and Review: </b>a. The Platform reserves the right to review and approve all
								advertisements before their placement on the Platform. b. The Platform may reject or remove any
								advertisement that violates these Policies or the Platform's advertising guidelines. c. The
								Platform may determine the placement, positioning, and frequency of advertisements on its
								platform, subject to any specific placement requests made by the Advertiser.
							</li>
							<li>
								<b>Payment and Fees: </b>a. Advertisers agree to pay the Platform the agreed-upon fees for the
								advertisement services as outlined in the separate payment schedule provided by the Platform. b.
								Payment terms and methods shall be specified by the Platform and agreed upon by the Advertiser. c.
								The Platform reserves the right to modify the fees and payment terms with reasonable notice to
								Advertisers.
							</li>
							<li>
								<b>Intellectual Property: </b>a. Advertisers represent and warrant that they have all necessary
								rights, licenses, and permissions to use the advertisement content and that the content does not
								infringe upon any third-party intellectual property rights. b. By displaying advertisements on the
								Platform, Advertisers grant the Platform a non-exclusive, worldwide, royalty-free license to use,
								reproduce, modify, and display the advertisements solely for the purpose of providing the
								advertising services.
							</li>
							<li>
								<b>Fraud and Scam Prevention: </b>a. Advertisements must not engage in fraudulent or deceptive
								practices, including but not limited to false claims, misleading statements, or misrepresented
								offers. b. Advertisements must not promote pyramid schemes, Ponzi schemes, or any other fraudulent
								schemes. c. Advertisements must not request sensitive personal information or engage in phishing
								activities. d. The Platform employs fraud detection mechanisms and reserves the right to take
								necessary actions, including rejecting or removing advertisements, to prevent fraudulent or scam
								activities.
							</li>
							<li>
								<b>Termination: </b>a. Either Party may terminate the advertisement services by providing written
								notice to the other Party. b. Upon termination, the Advertiser shall pay for all advertisement
								placements completed before the termination date.
							</li>
							<li>
								<b>Data and Privacy: </b>a. The Platform may collect and use data related to the advertisement
								services, subject to its privacy policy and applicable data protection laws. b. Advertisers shall
								comply with all applicable data protection and privacy laws when collecting and using user data
								obtained through the advertisements.
							</li>
							<li>
								<b>Confidentiality: </b>a. The Parties agree to keep all confidential information received during
								the course of this Agreement confidential and not disclose it to any third party without prior
								written consent.
							</li>
							<li>
								<b>Amendments: </b>The Platform reserves the right to update or modify these Policies at any time.
								Advertisers will be provided with reasonable notice of any changes.
							</li>
							<li>
								<b>Governing Law and Jurisdiction: </b>These Policies shall be governed by and construed in
								accordance with the laws of INDIAN COURT IN THE STATE OF WEST BENGAL, any disputes arising out of
								or in connection with these Policies shall be subject to the exclusive jurisdiction of the courts
								of [INDIAN COURT IN THE STATE OF WEST BENGAL].
							</li>
							<li>
								<b>Severability: </b>If any provision of these Policies is found to be invalid or unenforceable,
								the remaining provisions shall continue to be valid and enforceable to the fullest extent
								permitted by law.
							</li>
							<li>
								The Platform will cooperate with law enforcement agencies and regulatory authorities in
								investigating and addressing fraudulent or scam-related activities conducted through its
								advertising services.
							</li>
							<li>
								The Platform will investigate reported advertisements promptly and take appropriate action,
								including removing the advertisements and taking necessary measures against the responsible
								Advertiser's account.
							</li>
							<li>
								Reporting and Removal of Fraudulent or Scam Advertisements: Users are encouraged to report any
								advertisements that they believe to be fraudulent or scam-related.
							</li>
							<li>
								Advertisements must not engage in phishing or other fraudulent activities aimed at stealing users'
								personal information or financial data. Advertisements must not use deceptive tactics to
								manipulate or trick users into making unwanted purchases, subscriptions, or commitments.
							</li>
							<li>
								Advertisements must not request personal or sensitive information from users, such as credit card
								details, social security numbers, or login credentials, unless necessary for a legitimate and
								secure transaction.
							</li>
						</ol>
					</article>
				</div>
			</Modal>
		</div>
	);
};

export default TermsAndCondition;
